<template>
    <v-dialog
            :value="downloadTrackDialogVisible"
            max-width="570"
            @input="$emit('input')">

        <div class="coming_soon_dialog style_dialog downloads_dialog">
            <h2 class="header">
                <span class="border_head" v-if="isFreeUserPlan()">Free Account</span>
                <span class="border_head" v-else>Free Downloads</span>
            </h2>
            <div class="cnt">
                <template v-if="(CHECK_MUSIC_RELEASE_DOWNLOAD.limit - CHECK_MUSIC_RELEASE_DOWNLOAD.downloaded) > 0">
                    You have <span class="green_selected">{{ CHECK_MUSIC_RELEASE_DOWNLOAD.limit - CHECK_MUSIC_RELEASE_DOWNLOAD.downloaded }}</span> out of
                    <span class="green_selected">{{ CHECK_MUSIC_RELEASE_DOWNLOAD.limit }}</span> free Releases downloads left today
                </template>
                <template v-else>
                    <span>
                        You’ve reached your download limit for today<br>
					    <span v-if="isFreeUserPlan()">Try again tomorrow or upgrade to premium</span>
                    </span>
                </template>
            </div>
            <div class="wrap_actions">
                <button type="button" class="is-nope" :class="{'center': !isWithinLimit() && !isFreeUserPlan()}" @click="cancel()" v-ripple>
                    <span v-if="!isWithinLimit() && !isFreeUserPlan()">Ok</span>
                    <span v-else>Cancel</span>
                </button>
                <button type="button"  v-if="isWithinLimit()"  class="is-nope"
                        @click="download" v-ripple>Download
                </button>
                <button type="button" v-else-if="isFreeUserPlan()" @click="upgrade" class="c2a_btn">
                    Upgrade
                    <span class="arrow">
                        <span class="line_svg"></span>
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
	    <errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const errorDialog = () => import('@/components/dialogs/error');

export default {
    name: "downloadAlbum",
    props: ['value'],
    data() {
        return {
	        errorDialog: false,
        }
    },
	components: {
		errorDialog
	},
    computed: {
        ...mapGetters(['CHECK_MUSIC_RELEASE_DOWNLOAD', 'MUSIC_RELEASE_DOWNLOAD_DATA', 'DOWNLOAD_MUSIC_RELEASE_LINK', 'PROFILE']),
        downloadTrackDialogVisible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },

    methods: {
        ...mapActions(['GET_DOWNLOAD_MUSIC_RELEASE_LINK']),
        cancel() {
            this.downloadTrackDialogVisible = false;
        },
        download() {
            this.downloadTrackDialogVisible = false;

            this.GET_DOWNLOAD_MUSIC_RELEASE_LINK(this.MUSIC_RELEASE_DOWNLOAD_DATA.id)
                .then(() => {
                    if (this.DOWNLOAD_MUSIC_RELEASE_LINK) {
                        let link = document.createElement('a');
                        link.href = this.DOWNLOAD_MUSIC_RELEASE_LINK;
                        link.download = `${this.MUSIC_RELEASE_DOWNLOAD_DATA.title}`;
                        link.click();
                    } else {
						this.errorDialog = true;
                    }
                })
        },
        upgrade() {
            this.$router.push({name: 'memberships'});
        },
        isFreeUserPlan() {
            return this.PROFILE === 'free';
        },
        isWithinLimit() {
            return (this.CHECK_MUSIC_RELEASE_DOWNLOAD.limit - this.CHECK_MUSIC_RELEASE_DOWNLOAD.downloaded) > 0
        }
    }
}
</script>

<style scoped>
button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    pointer-events: none;
}
button {
    &.center {
        margin: 0 auto;
    }
}
</style>
